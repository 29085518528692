import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { LiveViewContainer } from "./_liveView.styled";
import { httpGet } from "../../utils/https";
import { Statistic, Row, Col } from "antd";
import { firebaseDb } from "../../configs/fire";
import DeviceMap from "../../component/device-map/index.js";
import { URLS } from "../../utils/urls.js";
import moment from "moment";
function LiveView({
  selectedCampaign,
  selectedCampaignData,
  formsData,
  viewsCount,
  scansCount,
  campaignSummeryData,
  campaignId,
}) {
  const [deviceStatusList, setDeviceStatusList] = useState([]);
  const [activeDevices, setActiveDevices] = useState([]);
  const [deviceLocations, setDeviceLocations] = useState([]);
  const [deviceSerialCollection, setDeviceSerialCollection] = useState([]);
const [intervalTracker, setIntervalTracker]=useState(0);
  const initialTimeStamp = useRef(Date.now());
  const isDataLoadedOnce = useRef(false);
  const mapTimeDelayedInSecs = 2;

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalTracker(prevIntervalTracker => prevIntervalTracker + 1)
    }, 15000); 
    // Clean up function to clear the interval when the component unmounts or the dependency array changes
    return () => clearInterval(interval);
  }, [campaignId]);

  useLayoutEffect(()=>{
    // calling Firebase Api Whenever deviceSerial stores in FetchCampaignLiveDevices Function
if(deviceSerialCollection?.length>0){
  fetchDeviceLocationStatusList();
}
  },[intervalTracker,deviceSerialCollection])


  useLayoutEffect(()=>{
    // calling Api Whenever Campaign Id Updates on Interval Updates in 15 Secs
    fetchCampaignLiveDevices();
  },[intervalTracker, campaignId])

  const fetchCampaignLiveDevices = () => {
    let queryParams = new URLSearchParams();
    queryParams.append("campaign_id", campaignId);

    let url = `${
      URLS?.CAMPAIGNS?.CAMPAIGN_LIVE_DEVICES
    }?${queryParams.toString()}`;

    if (campaignId) {
      httpGet(url)
        .then((res) => {
          if (res?.status == 200) {
            setDeviceLocations(res?.data?.documents);

            const serialNumberCollection = res?.data?.documents?.map(
              (item, index) => {
                return item?.device_serial;
              }
            );
            setDeviceSerialCollection(serialNumberCollection);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const convertUnixToDateFormat = (
    dateString,
    dateFormat = "DD MMMM YYYY hh:mm A"
  ) => {
    return (
      (dateString && moment.unix(dateString).isValid()
        ? moment.unix(dateString).format(dateFormat)
        : "N/A") || ""
    );
  };


  // Firebase Listens One Time code Starts

  const fetchDeviceLocationStatusList = () => {
    const ref = firebaseDb.ref(`device_location_updates`);
    ref
      .once("value")
      .then((snapshot) => {
        const campaignStatusListData = snapshot.val();
        const tempList = [];
        // Process the data and populate tempList
        Object.keys(campaignStatusListData || {}).forEach((item) => {
      if(deviceSerialCollection?.includes(campaignStatusListData[item]?.device_serial)){
        const deviceStatusStructure = {
          // batteryPercent: deviceStatusListData[data].battery_percent || '',
          // binaryCode: deviceStatusListData[data].binary_code || '',
          ...campaignStatusListData[item],
          is_ad_playing:true,
          deviceSerial: campaignStatusListData[item]?.device_serial || "",
          position: {
            lat: campaignStatusListData[item]?.latitude || "",
            lng: campaignStatusListData[item]?.longitude || "",
          },
          // isCharging:
          //   deviceStatusListData[data].is_charging == true
          //     ? 'Charging'
          //     : 'Not Charging',
          // screenStatus:
          //   deviceStatusListData[data].is_screen_on == true ? 'On' : 'Off',
          // wifiName: deviceStatusListData[data].required_wifi_name
          //   ? deviceStatusListData[data].required_wifi_name
          //   : '',
          updatedAt: campaignStatusListData[item]?.updated_at
            ? convertUnixToDateFormat(
                campaignStatusListData[item]?.updated_at
              )
            : "-",
        };
        tempList.push(deviceStatusStructure);
      }else{

        const deviceStatusStructure = {
          // batteryPercent: deviceStatusListData[data].battery_percent || '',
          // binaryCode: deviceStatusListData[data].binary_code || '',
          ...campaignStatusListData[item],
          is_ad_playing:false,
          deviceSerial: campaignStatusListData[item]?.device_serial || "",
          position: {
            lat: campaignStatusListData[item]?.latitude || "",
            lng: campaignStatusListData[item]?.longitude || "",
          },
          // isCharging:
          //   deviceStatusListData[data].is_charging == true
          //     ? 'Charging'
          //     : 'Not Charging',
          // screenStatus:
          //   deviceStatusListData[data].is_screen_on == true ? 'On' : 'Off',
          // wifiName: deviceStatusListData[data].required_wifi_name
          //   ? deviceStatusListData[data].required_wifi_name
          //   : '',
          updatedAt: campaignStatusListData[item]?.updated_at
            ? convertUnixToDateFormat(
                campaignStatusListData[item]?.updated_at
              )
            : "-",
        };

        tempList.push(deviceStatusStructure);
      }
        });
        // Update state or perform further actions

        //  const mixAciveDevices= tempList?.map((val,ind)=>{

        //  })

        setActiveDevices(tempList);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // Firebase Listens One Time code Ends

  // Firebase Socket Establishment Code Starts
  // const fetchDeviceLocationStatusList = () => {

  //   const ref = firebaseDb.ref(`device_location_updates`);
  //   ref.on("value", (response) => {

  //     const campaignStatusListData = response.val();
  //     const tempList = [];
  //     Object.keys(campaignStatusListData || {}).map((item) => {
  //       const deviceStatusStructure = {
  //         // batteryPercent: deviceStatusListData[data].battery_percent || '',
  //         // binaryCode: deviceStatusListData[data].binary_code || '',
  //         ...campaignStatusListData[item],
  //         deviceSerial: campaignStatusListData[item]?.device_serial || '',
  //         position: {
  //           lat: campaignStatusListData[item]?.latitude || '',
  //           lng: campaignStatusListData[item]?.longitude || '',
  //         },
  //         // isCharging:
  //         //   deviceStatusListData[data].is_charging == true
  //         //     ? 'Charging'
  //         //     : 'Not Charging',
  //         // screenStatus:
  //         //   deviceStatusListData[data].is_screen_on == true ? 'On' : 'Off',
  //         // wifiName: deviceStatusListData[data].required_wifi_name
  //         //   ? deviceStatusListData[data].required_wifi_name
  //         //   : '',
  //         updatedAt: campaignStatusListData[item]?.updated_at
  //           ? convertUnixToDateFormat(campaignStatusListData[item]?.updated_at)
  //           : '-',
  //       };
  //       tempList.push(deviceStatusStructure);
  //     });

  //     let currentTimeStamp = Date.now();
  //     if (
  //       (currentTimeStamp - initialTimeStamp.current) / 1000 >=
  //         mapTimeDelayedInSecs ||
  //       !isDataLoadedOnce.current
  //     ) {
  //       initialTimeStamp.current = Date.now();
  //       isDataLoadedOnce.current = true;
  //       setActiveDevices(tempList);
  //     }

  //   });
  // };

  // Firebase Socket Establishment Code Ends

  // const fetchDeviceStatusList = () => {
  //   const ref = firebaseDb.ref("device_status");
  //   ref.on("value", (response) => {

  //     const deviceStatusListData = response.val();

  //     const tempList = [];
  //     for (let data in deviceStatusListData) {
  //       const deviceStatusStructure = {
  //         batteryPercent: deviceStatusListData[data].battery_percent || "",
  //         binaryCode: deviceStatusListData[data].binary_code || "",
  //         deviceSerial: deviceStatusListData[data].device_serial || "",
  //         position: {
  //           lat: deviceStatusListData[data].current_lat || "",
  //           lng: deviceStatusListData[data].current_lng || "",
  //         },
  //         isCharging:
  //           deviceStatusListData[data].is_charging == true
  //             ? "Charging"
  //             : "Not Charging",
  //         screenStatus:
  //           deviceStatusListData[data].is_screen_on == true ? "On" : "Off",
  //         updatedAt: deviceStatusListData[data].updated_at,
  //       };
  //       tempList.push(deviceStatusStructure);
  //     }
  //     let currentTimeStamp = Date.now();
  //     if (
  //       (currentTimeStamp - initialTimeStamp.current) / 1000 >=
  //         mapTimeDelayedInSecs ||
  //       !isDataLoadedOnce.current
  //     ) {
  //       initialTimeStamp.current = Date.now();
  //       isDataLoadedOnce.current = true;
  //       setDeviceStatusList(tempList);
  //     }
  //   });
  // };



  return (
    <LiveViewContainer>
      <div className="container">
        {/* <div className="numberData">
          <Row gutter={24}>
            <Col span={8}>
              <Statistic title="Spots" value={viewsCount && viewsCount.count} />
            </Col>
            <Col span={8}>
              <Statistic title="Scans" value={scansCount && scansCount.count} />
            </Col>
            <Col span={8}>
              <Statistic
                title="Leads"
                value={
                  formsData.filter((e) => e.campaign_id == selectedCampaign)
                    .length || 0
                }
              />
            </Col>
          </Row>
        </div> */}
        <div className="map">
          <div className="map-box"></div>
        </div>
        {activeDevices && activeDevices.length > 0 ? (
          <DeviceMap
            locations={activeDevices}
            // activeDevices={activeDevices}
            // locations={deviceStatusList}

            selectedCampaign={selectedCampaign}
            selectedCampaignData={selectedCampaignData}
            deviceLocations={deviceLocations}
          />
        ) : (
          <DeviceMap locations={[]} />
        )}
        <div className="numberData">
          <h1 className="heading">Campaign Summary</h1>
          <Row gutter={24}>
            <Col span={8}>
              <Statistic
                title="Spots"
                value={campaignSummeryData && campaignSummeryData.viewCount}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Scans"
                value={campaignSummeryData && campaignSummeryData.scanCount}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Leads"
                value={campaignSummeryData && campaignSummeryData.leadCount}
              />
            </Col>
          </Row>
        </div>
      </div>
    </LiveViewContainer>
  );
}

export default LiveView;
