import React, { useEffect, useState, Fragment } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Space, Table, Button, Spin, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { CSVLink } from "react-csv";
import { httpGet } from "../../../utils/https";
import { URLS } from "../../../utils/urls";
import Downloadmodal from "../../download_modal/Downloadmodal";
import GoTo from "../../../component/go-to-page";
import { createFromIconfontCN } from '@ant-design/icons';



const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});

function ViewsTable({ spotsDetails, selectedCampaign, showDetails, totalCount, deliveredCampaignApi, tableLoadingTracker }) {
  const [adServingLogs, setAdServingLogs] = useState([]);
  const [CSVData, setCSVData] = useState([]);
  const ref = React.useRef(null);
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)


  const handlePaginationChange = (pagination, filters, sorter) => {
    const skip = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
  
    setCurrentPage(pagination?.current)
    deliveredCampaignApi(pagination?.current, pagination?.pageSize)

  };
  
  const headers = [
    { label: "Timestamp", key: "Timestamp" },
    { label: "Lat", key: "Deliverylocation.lat" },
    { label: "Long", key: "Deliverylocation.long" },
    { label: "Device Serial", key: "device_serial" },
    { label: "Taxi Number", key: "texi_number" },
    { label: "Verified", key: "verified" },
  ];
 


  const handleExport = async ({ limit = totalCount } = {}) => {
    setOpen(!open)
  };

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => {
        return (function Actions() {
          return (
            <>{(date && moment(date * 1000).format("MMMM D, YYYY h:m A")) || ""}</>
          );
        })();
      },
    },
    {
      title: "Device Serial",
      dataIndex: "device_serial",
      key: "device_serial",
      render: (data) => {
        return (function Actions() {
          return <>{data || ""}</>;
        })();
      },
    },
    {
      title: "Taxi Number",
      dataIndex: "taxi_number",
      key: "taxi_number",
      render: (data) => {
        return (function Actions() {
          return <>{data || ""}</>;
        })();
      },
    },
    {
      title: "Delivery Location",
      dataIndex: "served_location",
      key: "served_location",
      render: (data) => {
        return (function Actions() {
          if (!data || !data.latitude || !data.longitude) {
            return <span>No location available</span>;
          }

          const googleMapsUrl = `https://www.google.com/maps?q=${data.latitude},${data.longitude}`;

          return (
            <>
              <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" className="d-flex align-items-center">
                Delivery Location
                <IconFont type="icon-tuichu" className="ms-1" />
              </a>
            </>
          );
        })();
      },
    },
    {
      title: "Verified",
      key: "Verified",
      render: () => (
        <Space>
          <CheckOutlined />
        </Space>
      ),
    },
  ];
  return (
    <Fragment>
      {showDetails && (
        <div className="table">
          <Table
            columns={columns}
            dataSource={spotsDetails?.documents}
            onChange={handlePaginationChange}
            pagination={{ current: currentPage, defaultPageSize: 20, total: totalCount }}
            scroll={{ x: 700, y: 900 }}
            loading={tableLoadingTracker}
          />
          <GoTo
            currentPage={currentPage}
            pageSize={20} 
            total={totalCount}
            onChange={(pageNumber) => handlePaginationChange({
              "current": pageNumber,
              "pageSize": 20,
              "defaultPageSize": 20,
              "total": totalCount
            })} 
            setCurrentPage={setCurrentPage}
          />
          <CSVLink
            data={CSVData}
            headers={headers}
            filename={"Spots_Report.csv"}
          >
            <span ref={ref}></span>
          </CSVLink>
          <div className="d-flex justify-content-end align-items-center mt-4">
            {isFetchingCSV ? (
              <div className="file-export-loading">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />}
                />
                <div>Data Fetching...</div>
              </div>
            ) : (
              <>
                <Button
                  className="downloadbtn"
                  onClick={handleExport}
                >
                  Export
                </Button>
                <Downloadmodal open={open} setOpen={setOpen} tabDetails={"Spots Report Export"} selectedCampaign={selectedCampaign}></Downloadmodal>
              </>

            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ViewsTable;
