import React, { useState, useEffect, useRef } from "react";
import { ViewsReportContainer } from "./_viewsReport.styled";
import { Statistic, Row, Col, Button, Spin } from "antd";
import moment from "moment";
import { httpGet } from "../../utils/https";
import { LoadingOutlined } from "@ant-design/icons";
import DemoColumn from "./bar_chart/barChart";
import ViewsTable from "./table/table";
import HeatMap from "../../component/heat-map";
import DateRangePickerFilter from "../../component/date-range-picker-filter";
import { URLS } from "../../utils/urls";
import * as XLSX from 'xlsx';


function ViewsReport({
  dateString,
  adServingLogs,
  viewsHeatMapDataLoading,
  selectedCampaign,
  viewsHeatMapLatLong,
  viewsCount,
  selectedCampaignData,
  onDatePickerChange,
  datePickerError,
  campaignId,
  dateChangeTracker,
  resetMapStyle,
}) {
  const [showDetails, setShowDetails] = useState(true);
  const startDate = dateString && dateString[0];
  const endDate = dateString && dateString[1];
  const [spotsDetails, setSpotsDetails] = useState();
  const [spotsCounts, setSpotsCounts] = useState();
  const [totalItems, setTotalItems] = useState();
  const [spotsGraph, setSpotsGraph] = useState([]);
  const [statsLoadingTracker, setStatsLoadingTracker] = useState(false);
  const [chartLoadingTracker, setChartLoadingTracker] = useState(false);
  const [tableLoadingTracker, setTableLoadingTracker] = useState(false);
  const [chartDownloadData, setChartDownloadData] = useState();
  const [exportData, setExportData] = useState([])
  const [geoJsonLink, setGeoJsonLink]=useState(null)
  const [isLoading, setIsLoading]=useState(false)


  useEffect(() => {
    deliveredCampaignApi();
    getSpotsMapsData();
  }, [campaignId]);

const getSpotsMapsData=()=>{
  setIsLoading(true)
  
let queryParams= new URLSearchParams()

queryParams.append('limit', 100000)

  let url= `${URLS?.SPOTS?.HEATMAP_SPOTS?.replace('#CAMPAIGNID#', campaignId)}?${queryParams.toString()}`

  httpGet(url)
  .then((res)=>{
  setGeoJsonLink(res?.data)
  setIsLoading(false)
  }).catch((err)=>[
  setIsLoading(false)
  ])
}

  useEffect(() => {
    let url = `${URLS?.SPOTS?.GET_CAMPAIGN_SPOTS_COUNT.replace(
      "#CAMPAIGN_ID#",
      campaignId
    )}`;

    if (campaignId) {
      setStatsLoadingTracker(true);
      httpGet(url)
        .then((res) => {
          if (res?.status == 200) {
            setSpotsCounts(res?.data);
          } else {
            setSpotsCounts([]);
          }
          setStatsLoadingTracker(false);
        })
        .catch((err) => {
          setStatsLoadingTracker(false);
        });
    }
  }, [campaignId]);

  useEffect(() => {
    const newStartDate = moment(startDate).unix();
    const newEndDate = moment(endDate).endOf("day").unix();

    const queryParams = new URLSearchParams();
    if (newStartDate) queryParams.append("start_date", newStartDate);
    if (newEndDate) queryParams.append("end_date", newEndDate);
    let url = `${URLS?.SPOTS?.GET_CAMPAIGN_SPOTS_GRAPH_DATA.replace(
      "#CAMPAIGN_ID#",
      campaignId
    )}?${queryParams.toString()}`;

    if (campaignId && dateChangeTracker > 0) {
      setChartLoadingTracker(true);
      httpGet(url)
        .then((res) => {
          if (res?.status == 200) {
            setSpotsGraph(res?.data);
            setExportData(res?.data)
          } else {
            setSpotsGraph([]);
          }
          setChartLoadingTracker(false);
        })
        .catch((err) => {
          setChartLoadingTracker(false);
        });
    }
  }, [campaignId, dateChangeTracker]);

  const deliveredCampaignApi = (page, pageSize) => {
    const newStartDate = moment(startDate).unix();
    const newEndDate = moment(endDate).unix();

    const queryParams = new URLSearchParams();

    queryParams.append("page", page ? page : 1);
    queryParams.append("pageSize", pageSize ? pageSize : 20);

    let url = `${URLS?.SPOTS?.GET_SPOTS_CAMPAIGN_DETAILS.replace(
      "#CAMPAIGN_ID#",
      campaignId
    )}?${queryParams.toString()}`;

    if (campaignId) {
      setTableLoadingTracker(true);
      httpGet(url)
        .then((res) => {
          if (res?.status == 200) {
            setSpotsDetails(res?.data);
            setTotalItems(res?.totalItems);
          }
          setTableLoadingTracker(false);
        })
        .catch((err) => {
          setTableLoadingTracker(false);
        });
    }
  };

  const downloadChart = () => {
    const transformedData = exportData.map(item => ({
        Date: item.date,
        'Spots Count': item.count
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Create a new workbook and add the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a binary string of the workbook
    const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary string to an array buffer
    const buffer = new ArrayBuffer(workbookBinary.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < workbookBinary.length; i++) {
        view[i] = workbookBinary.charCodeAt(i) & 0xFF;
    }

    // Create a Blob from the array buffer and download it
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `report_spots_count_${campaignId}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

  return (
    <ViewsReportContainer>
      <div className="container">
        <div style={{ height: "100%", width: "100%" }} className="mt-5">
         
        <HeatMap
            selectedCampaignData={selectedCampaignData}
            campaignId={campaignId}
            resetMapStyle={resetMapStyle}
            geoJsonLink={geoJsonLink}
            loading={isLoading}
          />
          {selectedCampaignData &&
            selectedCampaignData[0] &&
            selectedCampaignData[0].campaign_start_date &&
            selectedCampaignData[0].campaign_end_date && (
              <div className="mapDateLabel mt-3 w-100 text-center d-flex justify-content-around">
                {selectedCampaignData[0].campaign_start_date &&
                  selectedCampaignData[0].campaign_end_date &&
                  selectedCampaignData[0].last_sync_date ? (
                  <>
                    <label>
                      {"From Date: "}
                      {moment(selectedCampaignData[0].campaign_start_date, [
                        "YYYY-MM-D HH:mm:ss",
                      ]).format("MMM D YYYY hh:mm A")}
                    </label>
                    <label>
                      {"To Date: "}
                      {moment(
                        selectedCampaignData[0].campaign_end_date
                      ).isAfter(moment(new Date()).format("YYYY-MM-DD"))
                        ? moment(
                          selectedCampaignData[0]?.last_sync_date ||
                          new Date(),
                          ["YYYY-MM-D HH:mm:ss"]
                        ).format("MMM D YYYY hh:mm A")
                        : moment(selectedCampaignData[0].campaign_end_date, [
                          "YYYY-MM-D HH:mm:ss",
                        ]).format("MMM D YYYY hh:mm A")}
                    </label>
                  </>
                ) : (
                  <label>
                    {
                      "Note: Data will be available after the 24 hours of creating the Campaign."
                    }
                  </label>
                )}
              </div>
            )}
        </div>
        <div className="numberData">
          <Row
            gutter={[{ xs: 64, sm: 86, md: 128, lg: 256 }, 24]}
            justify="center"
          >
            <Col
              xs={12}
              sm={12}
              md={12}
              className="d-flex flex-column align-items-center"
            >
              <Statistic
                title="Today's Spots"
                value={spotsCounts?.dailySpot}
                loading={statsLoadingTracker}
                responsive={{
                  xs: { fontSize: "12px" },
                  sm: { fontSize: "14px" },
                  md: { fontSize: "16px" },
                }}
              />
            </Col>

            <Col
              xs={12}
              sm={12}
              md={12}
              className="d-flex flex-column align-items-center"
            >
              <Statistic
                title="Last Week Spots"
                value={spotsCounts?.weeklySpot}
                loading={statsLoadingTracker}
                responsive={{
                  xs: { fontSize: "12px" },
                  sm: { fontSize: "14px" },
                  md: { fontSize: "16px" },
                }}
              />
            
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              className="d-flex flex-column align-items-center"
            >
              <Statistic
                title="Lifetime Spots"
                value={spotsCounts?.overallCount}
                loading={statsLoadingTracker}
                responsive={{
                  xs: { fontSize: "12px" },
                  sm: { fontSize: "14px"},
                  md: { fontSize: "16px" },
                }}
              />
            </Col>
          </Row>
        </div>

        <DateRangePickerFilter
          onChange={onDatePickerChange}
          datePickerError={datePickerError}
          dateString={dateString}
        />

        <div
          className="barChart"
          style={{ width: "100%", overflowX: "auto", overflowY: "scroll" }}
        >
          {spotsGraph?.length != 0 && (
            <DemoColumn
              lifeTimeViews={spotsGraph}
              startDate={startDate}
              endDate={endDate}
              chartLoadingTracker={chartLoadingTracker}
              chartDownloadData={chartDownloadData}
              setChartDownloadData={setChartDownloadData}
            />
          )}
        </div>
        {spotsGraph?.length != 0 && (
          <div className="d-flex justify-content-end w-100 mt-4 mb-5">
            <Button className="downloadChart" onClick={downloadChart}>
              Download Report
            </Button>
          </div>
        )}

            <ViewsTable
              spotsDetails={spotsDetails}
              selectedCampaign={selectedCampaign}
              totalCount={totalItems}
              showDetails={showDetails}
              deliveredCampaignApi={deliveredCampaignApi}
              tableLoadingTracker={tableLoadingTracker}
            />
      </div>
    </ViewsReportContainer>
  );
}

export default ViewsReport;
