import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { DatePicker } from "antd";
import { DatePickerContainer } from "./_date-picker.styled";
import moment from "moment";


const { RangePicker } = DatePicker;

const DateRangePickerFilter = (props) => {
  const {
    onChange,
    format = "YYYY-MM-DD",
    datePickerError,
    dateString,
  } = props;
  // const containerRef = useRef(null);
  // const [calendarContainer, setCalendarContainer] = useState(null);

  // useEffect(() => {
  //   const updateCalendarContainer = () => {
  //     setCalendarContainer(containerRef.current || document.body);
  //   };

  //   updateCalendarContainer();

  //   window.addEventListener("scroll", updateCalendarContainer);

  //   return () => {
  //     window.removeEventListener("scroll", updateCalendarContainer);
  //   };
  // }, []);
  
  const endDate = moment().subtract(1, 'days').endOf('day');
  const startDate = moment().subtract(7, 'days').startOf('day');
 
  useLayoutEffect(()=>{
    const endDate = moment().subtract(1, 'days').endOf('day');
  const startDate = moment().subtract(7, 'days').startOf('day');
    // onChange("", [startDate.format("YYYY-MM-DD HH:mm:ss") ,endDate.format("YYYY-MM-DD HH:mm:ss")])
    onChange("", ['',''])
  },[])

  return (
    <DatePickerContainer>
      <RangePicker
        // showTime={{ format: "HH:mm" }}
        format={format}
        onChange={onChange}
        defaultValue={
          dateString && dateString.length >= 1 && dateString[0] && dateString[1]
            ? [
                moment(dateString[0], "YYYY-MM-DD"),
                moment(dateString[1], "YYYY-MM-DD"),
              ]
            : undefined
        }
      />
      <p className="date-error">{datePickerError}</p>
    </DatePickerContainer>
  );
};
export default DateRangePickerFilter;
