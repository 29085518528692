import styled from "styled-components";
export const LoginContainer = styled.div`
  height: 100vh;
  width: 100%;
  .nav {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
  }
  .logo {
    position: relative;
    font-size: larger;
    font-weight: bold;
    font-family: cursive;
  }
  .contact {
    position: relative;
    font-weight: 800;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: black;
  }
  .login-container {
    font-family: "Poppins", sans-serif;
    background-color: #f9f9f9;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
    @media only screen and (max-width: 576px) {
      padding: 1rem;
    }
  }

  .login-container h1 {
    margin-top: 19vh;
    margin-bottom: 5vh;
  }

  .loginBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding-top: 1.5vh;
    max-width: 70vh;
    box-shadow: 0px 3px 25px #00000029;
    background: white;
    z-index: 99;
    padding: 40px;
    border-radius:20px;
  }

  .loginBox .ant-input-lg {
    padding: 10px 16px;
  }
  .ant-form-item-label {
    font-weight: 800;
  }
  .ant-input-affix-wrapper,
  .ant-input,
  .ant-btn {
    border-radius: 100px;
  }
  .ant-btn {
    background: #000000;
    color: white;
    height: 6vh;
    border: none;
    font-weight: 700;
    font-size: larger;
    margin-bottom: 2vh;
  }
  .ant-form-item {
    margin-bottom: 7px;
  }
  .ant-form-item-control {
    margin: inherit;
  }

  .ant-checkbox-wrapper,
  .helpSignin {
    text-decoration: none;
    color: #707070;
  }

  .backgroundHeading {
    font-family: "Roboto", sans-serif;
    position: absolute;
    margin-top: 5vh;
    font-size: 10vw;
    font-weight: 900;
    z-index: 0;
    opacity: 0.1;

@media only screen and (max-width: 2561px) {
    font-size: 4vw;
      margin-top: 3vh;
    }

    @media only screen and (max-width: 1921px) {
    font-size: 5vw;
      margin-top: 3vh;
    }

    @media only screen and (max-width: 1440px) {
    font-size: 7vw;
      margin-top: 3vh;
    }
    @media only screen and (max-width: 992px) {
      margin-top: 4vh;
    }
      @media only screen and (max-width: 850px) {
      margin-top: 7vh;
    }
    @media only screen and (max-width: 576px) {
      margin-top: 12vh;
    }
      @media only screen and (max-width: 425px) {
      margin-top: 15vh;
    }
  }
  label.ant-form-item-required {
    font: normal normal 800 16px Poppins !important;
    letter-spacing: 0px;
    color: #000000;
    height: unset;
  }
  span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg.ant-input-password {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-checkbox-wrapper,
  .helpSignin {
    font: normal normal 300 16px Roboto;
    color: #707070;
    letter-spacing: 1.8px;
  }
`;
