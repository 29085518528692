import styled from "styled-components";
export const LiveViewContainer = styled.div`
  width: 100%;

  .container {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin-bottom: 10vh;
  }
  .numberData {
    margin-top: 48px;
    width: 100%;
  }
  .ant-statistic-title {
    margin-bottom: 4px;
    color: black;
    font: normal normal bold 20px/34px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-align: center !important;
  }
  .ant-statistic-content {
    text-align: center !important;
  }
  .ant-statistic-content-value {
    display: inline-block;
    direction: ltr;
    font: normal normal 300 60px/100px Roboto;
    letter-spacing: 0px;
    color: #636465;
    @media only screen and (max-width: 768px) {
      font-size: 30px;
    }
  }
  .map-box {
    border-radius: 5px;
    overflow: hidden;
  }
  .map {
    width: 100%;
    padding: 16px;
  }
  .heading {
    font: normal normal 900 24px/31px Poppins;
    letter-spacing: 0px;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
    @media only screen and (min-width: 2060px) {
      margin-top: 4%;
      font-size: 50px;
    }
  }
`;
