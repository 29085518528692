import React, { useState, useEffect } from "react";
import {
  // Table,
  Form,
  Button,
  DatePicker,
  Input,
  Card,
  Row,
  Col,
  Skeleton,
  Modal,
  Select,
  TimePicker,
} from "antd";
import moment from "moment";
import { URLS } from "../../utils/urls";
import { httpGet, httpPost } from "../../utils/https";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import './Downloadmodal.css'

const { RangePicker } = DatePicker;

const Downloadmodal = (props) => {
  const { open, setOpen, tabDetails, selectedCampaign } = props;

  const [customRange, setCustomRange] = useState(null);
  const [isRangePickerCleared, setIsRangePickerCleared] = useState(false);
  const [dayRange, setDayRange] = useState("7");
  const [selectedModalRange, setSelectedModalRange] = useState([]);
  const [modalBySerial, setModalBySerial] = useState([]);
  const [ModalSelectValue, setModalSelectValue] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [timePickerData, setTimePickerData] = useState([]);
  const [emailID, setEmailID] = useState("");

  useEffect(() => {
    updateDateRange(dayRange);
  }, []);


  const handleOk =() => {
    setConfirmLoading(true);
   
    let queryParams = {};

    if (selectedModalRange !== null)
      queryParams.start_date = moment(selectedModalRange[0]).unix();
    if (selectedModalRange !== null)
      queryParams.end_date = moment(selectedModalRange[1]).endOf('day').unix();
    if (ModalSelectValue?.length > 0)
      queryParams.deviceSerial = ModalSelectValue;
    if (timePickerData?.length > 0)
      queryParams.start_time = timePickerData[0] * 60;
    if (timePickerData?.length > 0)
      queryParams.end_time = timePickerData[1] * 60;
    if (emailID) queryParams.email_id = emailID;

    let url;

if(tabDetails == "Clicks Report Export"){
 url= URLS?.CLICKS?.DELIVERED_CAMPAIGN_CLICK_EXPORT_SHEET.replace(
  "#CAMPAIGN_ID#",
  selectedCampaign
);
}else{
url=URLS?.SPOTS?.DELIVERED_CAMPAIGN_EXPORT_SHEET.replace(
  "#CAMPAIGN_ID#",
  selectedCampaign
);
}

    httpPost(url, queryParams)
      .then((res) => {
        if (res?.status == 200) {
          setOpen(false);
          toast.success(res?.message, { duration: 7000 });
        } else toast.error(res?.message, { duration: 7000 });
        setConfirmLoading(false);
      })
      .catch((err) => {
        setConfirmLoading(false);
        toast.error(err?.message, { duration: 7000 });
      });

  };

  const handleCancel = () => {
    setOpen(false);
  };

  const rangeSelectorChange = (value) => {
    setDayRange(value);
    setCustomRange(null);
    updateDateRange(value);
  };
  

  // ========= RangePicker========
  const updateDateRange = (timeRange) => {
    const endDate = moment().subtract(1, "days");
    const startDate = moment().subtract(parseInt(timeRange), "days");
    setSelectedModalRange([startDate, endDate]);
    setIsRangePickerCleared(false);
  };

  const handleModalRangePicker = (dates, dateStrings) => {
    setSelectedModalRange(dates);
    setCustomRange(dates);
    if (dates == null) {
      setIsRangePickerCleared(true);
    } else {
      setIsRangePickerCleared(false);
    }
  };

  // ========= MultiSelect =======

  const handleChange = (value) => {
    setModalSelectValue(value);
  };

  // =========== TimePicker =======

  const handleTimeChange = (value, formatString) => {
    setTimePickerData(formatString);
  };

  

  return (
    <div>
      <Modal
        title={
          <span style={{ fontSize: "22px", fontWeight: 700 }}>
            {tabDetails}
          </span>
        }
borderRadius='10px'
        visible={open}
       
        footer={false}
        onCancel={handleCancel}
        className="download-modal" 
      >
        <Form layout="vertical" onFinish={handleOk}>
          <div className="mb-3">
            <label style={{ fontSize: "16px", fontWeight: 500 }}>Range</label>
            <Select
              style={{
                width: "100%",
              }}
              onChange={rangeSelectorChange}
              // defaultValue={'7'}
              value={
                customRange
                  ? "custom"
                  : isRangePickerCleared
                  ? "Custom Range"
                  : dayRange
              }
              options={[
                {
                  value: "7",
                  label: "Last 7 Days",
                },
                {
                  value: "15",
                  label: "Last 15 Days",
                },
                {
                  value: "30",
                  label: "Last Month",
                },
                customRange && {
                  value: "custom",
                  label: `Custom Range`,
                },
              ].filter(Boolean)}
            />
          </div>

          <div className="mb-3">
            <label style={{ fontSize: "16px", fontWeight: 500 }}>
              Custom Range
            </label>
            <RangePicker
              // disabledDate={disabledDate}
              style={{ width: "100%" }}
              // showTime={{ format: "hh:mm a" }}
              format="DD/MM/YYYY"
              onChange={handleModalRangePicker}
              value={selectedModalRange}
            />
          </div>

          {/* <div className="mb-3">
            <label style={{ fontSize: "16px", fontWeight: 500 }}>
              Serial Number
            </label>
            <Select
              mode="multiple"
              size={"middle"}
              placeholder="Please Select Serial Number"
              // defaultValue={['a10', 'c12']}
              onChange={handleChange}
              style={{
                width: "100%",
              }}
              // filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterOption={(input, option) =>
                option?.label
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={modalBySerial}
              value={ModalSelectValue}
            />
          </div> */}

          {/* <div className="mb-3">
            <label style={{ fontSize: "16px", fontWeight: 500 }}>
              Ride Time
            </label>
            <TimePicker.RangePicker
              format="mm"
              minuteStep={1}
              onChange={handleTimeChange}
              placeholder={["Min Time", "Max Time"]}
              style={{
                width: "100%",
              }}
            />
          </div> */}
          <Form.Item
            label={
              <span style={{ fontSize: "16px", fontWeight: 500 }}>Email</span>
            }
            name="Email"
            rules={[
              {
                required: true,
                message: "Please Enter Your Email!",
              },
              {
                type: "email",
                message: "This field must be a valid email.",
              },
            ]}
          >
            <Input onChange={(e) => setEmailID(e.target.value)}></Input>
          </Form.Item>
          <div className="d-flex justify-content-end">
            <Button
              style={{
                borderRadius: "20px",
                backgroundColor: "white",
                padding: "5px 30px 30px 30px",
                marginRight: "10px",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              style={{
                borderRadius: "20px",
                backgroundColor: "black",
                borderColor: "black",
                padding: "5px 30px 30px 30px",
                color: "white",
              }}
              htmlType="submit"
            >
              {confirmLoading ? "Exporting..." : "Export"}
            </Button>
          </div>
        </Form>
      </Modal>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Downloadmodal;
