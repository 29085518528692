import React, { useEffect, useState } from "react";
import { LoginContainer } from "./_login.styled";
import { Form, Input, Button, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { httpPost } from "../../utils/https";
import logo from "../../assets/images/ic_binary_logo.svg";
import { URLS } from "../../utils/urls";
import toast, { Toaster } from "react-hot-toast";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [rememberCredentials, setRememberCredentials]=useState(false);
  const [credentials, setCredentials]=useState({
    username:"kon",
    password:""
  })
  const navigate = useNavigate();

  const onFinish = (values) => {
    setIsLoading(true);
    const url = URLS?.USER?.SIGN_IN;
    httpPost(url, {
      email: values?.username || "",
      password: values?.password || "",
      type:'Reporting'
    })
      .then((res) => {
        if (res?.status === 200) {
            localStorage.setItem("token", JSON.stringify(res?.data?.token))
            localStorage.setItem("user_id", JSON.stringify(res?.data?.userDetail?._id))
            localStorage.setItem("email", JSON.stringify(res?.data?.userDetail?.email))
            localStorage.setItem("first_name", JSON.stringify(`${res?.data?.userDetail?.first_Name ? res?.data?.userDetail?.first_Name : null}`))
            localStorage.setItem("last_name", JSON.stringify(`${res?.data?.userDetail?.last_Name ? res?.data?.userDetail?.last_Name :null}`))
          toast.success(res?.message ? res?.message :'Something went wrong', { duration: 6000 } );
            navigate("/");
        } else {          
          toast.error(res?.error? res?.error :res?.message ? res?.message :'Something went wrong', { duration: 6000 } );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.error?err.error:'Something went wrong', { duration: 6000 } );
        setIsLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

const onCredentialChangeHandler=(e)=>{
setCredentials({...credentials, [e.target.name]:e.target.value})
if(rememberCredentials){
  if(e.target.name=="username"){
    localStorage.setItem("username",e.target.value)
  }else{
    localStorage.setItem("password",e.target.value) 
  }
}
}

const handleRemember=()=>{
  const inputValue=!rememberCredentials
setRememberCredentials(!rememberCredentials)
  if(inputValue){
    localStorage.setItem("username",credentials?.username)
    localStorage.setItem("password",credentials?.password) 
  }
}

useEffect(()=>{
const username=localStorage.getItem("username")
const password=localStorage.getItem("password")
setCredentials({username:username,password:password})
},[])

  return (
    <LoginContainer>
      <div className="login-container">
        <div className="nav">
          {" "}
          <span className="logo">
            <img src={logo} alt="logo" />
          </span>{" "}
         
        </div>
        <h1 style={{ fontWeight: "800" }}>Login to your account</h1>
        <div className="backgroundHeading">Reporting Console</div>
        <div className="loginBox">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
            className="login-form w-100"
            layout="vertical"
            fields={[
              {
                name: ["username"],
                value: credentials?.username,
              },
              {
                name: ["password"],
                value: credentials?.password,
              },
            ]}
          >
            <Form.Item
              label="Email"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "The input is not a valid email!",
                },
              ]}
            >
              <Input autoComplete="new-email" 
              name="username" value={credentials?.username} onChange={(e)=>onCredentialChangeHandler(e)}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              className="mt-3"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password autoComplete="new-password" type="password" 
              name="password" value={credentials?.password} onChange={(e)=>onCredentialChangeHandler(e)} 
              />
            </Form.Item>

           

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading}
                className="w-100 mt-5"
              >
                SIGN IN
              </Button>
           
            </Form.Item>
          </Form>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />

    </LoginContainer>
  );
}
