
const SERVER_URL = process.env.REACT_APP_API_URL ;
const API_BASE_URL = `${SERVER_URL}/api/v2`;


export const URLS = {
  USER: {
    SIGN_IN: `${API_BASE_URL}/user/userLogin`,
  },
  CAMPAIGNS: {
    GET_USER_CAMPAIGN_LIST: `${API_BASE_URL}/campaign/getCampaignList`,
  },
  SPOTS:{
    GET_CAMPAIGN_SPOTS_COUNT:`${API_BASE_URL}/campaign/spots/deliveredCampaignCount/#CAMPAIGN_ID#`,
    GET_CAMPAIGN_SPOTS_GRAPH_DATA:`${API_BASE_URL}/campaign/spots/spotsGraphData/#CAMPAIGN_ID#`,
    GET_SPOTS_CAMPAIGN_DETAILS:`${API_BASE_URL}/campaign/spots/deliveredCampaignDetail/#CAMPAIGN_ID#`,
    DELIVERED_CAMPAIGN_EXPORT_SHEET:`${API_BASE_URL}/campaign/deliveredCampExportSheet/#CAMPAIGN_ID#`,
    HEATMAP_SPOTS:`${API_BASE_URL}/spots/getHeatmapData/#CAMPAIGNID#`
  },
  CLICKS:{
  GET_CAMPAIGN_CLICKS_COUNT:`${API_BASE_URL}/campaign/click/getClicksCount/#CAMPAIGN_ID#`,
  GET_CAMPAIGN_CLICKS_DATA:`${API_BASE_URL}/campaign/click/getCampaignClickList/#CAMPAIGN_ID#`,
  GET_CAMPAIGN_GRAPH_DATA:`${API_BASE_URL}/campaign/click/getClicksGraphData/#CAMPAIGN_ID#`,
  DELIVERED_CAMPAIGN_CLICK_EXPORT_SHEET:`${API_BASE_URL}/campaign/click/exportClicks/#CAMPAIGN_ID#`

  }
};
