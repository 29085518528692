import React, { useState } from 'react'
import {Button} from 'antd'

const RedirectContent = () => {

    const [isConfirmed, setIsConfirmed]=useState(false)

    const confirmHandler=()=>{
        setIsConfirmed(true)
    }

  return (
    <div className='d-flex justify-content-center align-items-center mt-5'>
    <div className='mt-5'>

    { isConfirmed ?
    <div style={{fontSize:"20px", fontWeight:700}}>
        Thank You!
    </div>
    :
    <>
    <Button type="primary" className='me-3 ps-5 pe-5' onClick={confirmHandler}>Yes</Button>
      <Button type="danger" className='me-3 ps-5 pe-5' onClick={confirmHandler}>No</Button>
      </>
    }
    </div>
     
    </div>
  )
}

export default RedirectContent
