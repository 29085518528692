import styled from "styled-components";

export const MapBoxWindow = styled.div`
  height: 80vh;
  width: 100%;
  h5 {
    font: normal normal 300 16px Roboto;
    letter-spacing: 0px;
    color: #636465;
  }
  h5 > b {
    font-weight: 600;
  }
  h6 > b {
    font-weight: 600;
  }
  h6 {
    font-size: 14px;
    font: normal normal 300 14px Roboto;
    letter-spacing: 0px;
    color: #636465;
    margin-top: 15px;
    margin-bottom: 0;
  }
  button.mapboxgl-popup-close-button {
    padding: 4px 8px 4px 8px;
  }
`;
