import React, { useState } from "react";
import { InputNumber, Button } from "antd";

function GoTo({ currentPage, pageSize, total, onChange, setCurrentPage }) {
  const [pageNumber, setPageNumber] = useState(1);



  const handlePageChange = () => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(total / 20)) {
      onChange(pageNumber);
      setCurrentPage(pageNumber);
    } else {
      // Handle invalid page number
      alert("Invalid page number");
    }
  };

  return (
    <div style={{ marginTop: 10 }}>
      <span>Go To</span>
      <InputNumber
        min={1}
        max={Math.ceil(total / pageSize)}
        value={currentPage}
        onChange={(value) => setPageNumber(value)}
        onPressEnter={handlePageChange}
        controls={false}
        style={{margin:"0px 10px 0px 10px"}}
      />
      <span>Page</span>
      {/* <Button type="primary" onClick={handlePageChange}>
        Go
      </Button> */}
    </div>
  );
}

export default GoTo;
