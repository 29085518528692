import React, { useState, useRef, useEffect } from "react";
import { MapBoxWindow } from "./_heat-map.styled";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_PUBLIC_KEY ||
  "pk.eyJ1IjoiYmluYXJ5YWRtaW4iLCJhIjoiY2t6Mmw4ODNhMDFsMjJ2cWYweTFyb2ZuZyJ9.iBSQIqW6qM_KMV9S_ufb-w";

const HeatMap = (props) => {
  const { selectedCampaignData, campaignId, resetMapStyle, geoJsonLink, loading } = props || [];

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [initialCenter] = useState({
    lng: 55.2708,
    lat: 25.2048,
  });
  const [zoom] = useState(12);

  useEffect(() => {
    initMapbox();
  }, []); // Initialize map on component mount or when `resetMapStyle` changes
  
useEffect(()=>{
  removeAllLayers()
},[campaignId])


  useEffect(() => {
    if (!map.current) return; // Wait for the map to be initialized

    if(geoJsonLink){
      removeAllLayers()

    const addMapLayers = () => {
      // Add new source and layers
      map.current.addSource('heatmap-source', {
        'type': 'geojson',
        'data': geoJsonLink,
      });

      map.current.addLayer(
        {
          'id': 'heatmap-layer-heat',
          'type': 'heatmap',
          'source': 'heatmap-source',
          'paint': {
            'heatmap-weight':1,
            'heatmap-intensity':1,
            'heatmap-color':[
              "interpolate",
              ["exponential", 1],
              ["heatmap-density"],
              0,
              "hsla(0, 0%, 0%, 0)",
              0.33,
              "hsl(213, 100%, 49%)",
              0.67,
              "hsl(107, 100%, 46%)",
              1,
              "hsl(16, 99%, 38%)"
            ],
            'heatmap-radius':[
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              3.5,
              5,
              4.2,
              10,
              5.25,
              22,
              7
            ],
            'heatmap-opacity':0.8,
          },
        },
        
      );

  //     map.current.addLayer(
  //       {
  //         'id': 'earthquakes-point',
  //         'type': 'circle',
  //         'source': 'earthquakes',
  //         'minzoom': 7,
  //         // 'paint': {
  //         //   'circle-radius': [
  //         //     'interpolate',
  //         //     ['linear'],
  //         //     ['zoom'],
  //         //     7,
  //         //     ['interpolate', ['linear'], ['get', 'mag'], 1, 1, 6, 4],
  //         //     16,
  //         //     ['interpolate', ['linear'], ['get', 'mag'], 1, 5, 6, 50],
  //         //   ],
  //         //   'circle-color': [
  //         //     'interpolate',
  //         //     ['linear'],
  //         //     ['get', 'mag'],
  //         //     1,
  //         //     'rgba(33,102,172,0)',
  //         //     2,
  //         //     'rgb(103,169,207)',
  //         //     3,
  //         //     'rgb(209,229,240)',
  //         //     4,
  //         //     'rgb(253,219,199)',
  //         //     5,
  //         //     'rgb(239,138,98)',
  //         //     6,
  //         //     'rgb(178,24,43)',
  //         //   ],
  //         //   'circle-stroke-color': 'white',
  //         //   'circle-stroke-width': 1,
  //         //   'circle-opacity': [
  //         //     'interpolate',
  //         //     ['linear'],
  //         //     ['zoom'],
  //         //     7,
  //         //     0,
  //         //     8,
  //         //     1,
  //         //   ],
  //         // },
  //         'paint': {
  //   'circle-radius': 1, // Set a small radius for dots
  //   'circle-color': 'red', // Choose a color for the dots
  //   'circle-stroke-width': 1, // Optional: add a small stroke around the dots
  //   'circle-stroke-color': 'red', // Optional: stroke color
  //   'circle-opacity': 1 // Set opacity to 1 for solid dots
  // }
  //       },
  //       'waterway-label'
  //     );
    };

    if (map.current.isStyleLoaded()) {
      addMapLayers(); // If style is already loaded, add layers immediately
    } else {
      map.current.once('styledata', addMapLayers); // Otherwise, wait for the style to load
    }
  }

  }, [geoJsonLink]); // Reload map layers when `geoJsonLink` changes


  const removeAllLayers=()=>{
    if (map.current.getLayer('heatmap-layer-heat')) {
      map.current.removeLayer('heatmap-layer-heat');
    }
    // if (map.current.getLayer('earthquakes-point')) {
    //   map.current.removeLayer('earthquakes-point');
    // }
    if (map.current.getSource('heatmap-source')) {
      map.current.removeSource('heatmap-source');
    }
  }

  const initMapbox = () => {
    if (!map.current) {
      // Initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: resetMapStyle,
        center: initialCenter, // Starting position [lng, lat]
        zoom: zoom,
      });
      map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
      map.current.addControl(new mapboxgl.FullscreenControl());
    }
  };

  return (
    <MapBoxWindow style={{ position: "relative" }}>
    <div
      ref={mapContainer}
      className="heat-map-container"
      style={{ height: `100%`, width: `100%` }}
    />
    {loading && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 1,
        }}
      >
       <Spin tip="Loading" size="large">
        
      </Spin>
      </div>
    )}
  </MapBoxWindow>
  );
};

export default HeatMap;
