import React, { useEffect, useMemo, useState } from "react";
import { Statistic, Row, Col, Button } from "antd";
import moment from "moment";
import { ClicksReportContainer } from "./_clicksReport.styled";
import BarChart from "./bar_chart/barChart";
import ClicksTable from "./clicksTable/clicksTable";
import DateRangePickerFilter from "../../component/date-range-picker-filter";
import { formatDate } from "../../utils/helper";
import { URLS } from "../../utils/urls";
import { httpGet } from "../../utils/https";
import * as XLSX from 'xlsx';


function ClicksReport({
  dateString,
  selectedCampaign,
  selectedCampaignData,
  onDatePickerChange,
  datePickerError,
  clicksDataFetching,
  campaignClicksReportData,
  campaignId,
  dateChangeTracker
}) {
  const [showDetails, setShowDetails] = useState(true);
  const [clickDetails, setClickDetails] = useState([]);
  const [lifeTimeClicks, setlifeTimeClicks] = useState(0);
  const [lifeTimeClicksLoader, setlifeTimeClicksLoader] = useState(false);
  const [tableTotalCount, setTableTotalCount] = useState(0)
  const [chartLoadingTracker, setChartLoadingTracker] = useState(false)
  const [tableLoadingTracker, setTableLoadingTracker] = useState(false)
  const [rangeCount, setRangeCount] = useState(true);
  const [totalCount, setTotalCount] = useState(true);
  const startDate = dateString && dateString[0]
  const endDate = dateString && dateString[1]
  const [clicksGraph, setClicksGraph] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [chartDownloadData, setChartDownloadData] = useState();
  const [exportData, setExportData] = useState([])


  useEffect(() => {
    const url = `${URLS?.CLICKS?.GET_CAMPAIGN_CLICKS_COUNT.replace('#CAMPAIGN_ID#', campaignId)}`
    if (campaignId) {
      setlifeTimeClicksLoader(true)

      httpGet(url)
        .then((res) => {
          if (res?.status == 200) {
            setlifeTimeClicks(res?.data?.lifeTimeCount)
            setClicksGraph(res?.data?.sevenDaysCount);
            
          } else {
            setlifeTimeClicks(0)
            setClicksGraph([]);
          }
          setlifeTimeClicksLoader(false)
        })
        .catch((err) => {
          setlifeTimeClicksLoader(false)
        });
    }

  }, [campaignId])

  useEffect(() => {

    const newStartDate = moment(startDate).unix();
    const newEndDate = moment(endDate).endOf('day').unix();

    const queryParams = new URLSearchParams();
    if (newStartDate) queryParams.append("start_date", newStartDate);
    if (newEndDate) queryParams.append("end_date", newEndDate);
    let url = `${URLS?.CLICKS?.GET_CAMPAIGN_GRAPH_DATA.replace(
      "#CAMPAIGN_ID#",
      campaignId
    )}?${queryParams.toString()}`;


    if (campaignId && dateChangeTracker > 0) {
      setChartLoadingTracker(true)
      httpGet(url)
        .then((res) => {
          if (res?.status == 200) {
            setExportData(res?.data?.graphData)
          } else {
          }
          setChartLoadingTracker(false)
        })
        .catch((err) => {
          setChartLoadingTracker(false)
        });
    }
  }, [campaignId, dateChangeTracker]);

  useEffect(() => {
    clicksTableFetch()
  }, [campaignId])

  const clicksTableFetch = (page, pageSize) => {
    const queryParams = new URLSearchParams();

    queryParams.append("page", page ? page : 1);
    queryParams.append("pageSize", pageSize ? pageSize : 20);

    const url = `${URLS?.CLICKS?.GET_CAMPAIGN_CLICKS_DATA.replace('#CAMPAIGN_ID#', campaignId)}?${queryParams.toString()}`

    if (campaignId) {
      setTableLoadingTracker(true)

      httpGet(url)
        .then((res) => {
          if (res?.status == 200) {

            setClickDetails([res?.data])
            
            setTableTotalCount(res?.totalDocuments)
          } else {
            setClickDetails([])

          }
          setTableLoadingTracker(false)
        })
        .catch((err) => {
          setTableLoadingTracker(false)
        });
    }
  }




  const downloadChart = () => {
    // Transform the data to reorder columns and rename headers
    const transformedData = exportData.map(item => ({
        Date: item.date,
        'Clicks Count': item.count
    }));

    // Convert the transformed data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Create a new workbook and add the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a binary string of the workbook
    const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary string to an array buffer
    const buffer = new ArrayBuffer(workbookBinary.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < workbookBinary.length; i++) {
        view[i] = workbookBinary.charCodeAt(i) & 0xFF;
    }

    // Create a Blob from the array buffer and download it
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `report_clicks_count_${campaignId}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};


  return (
    <ClicksReportContainer>
      <div className="container">
        <div className="numberData">
          <Row
            gutter={[{ xs: 64, sm: 86, md: 128, lg: 256 }, 24]}
            justify="center"
          >
            <Col span={12} className="d-flex flex-column align-items-center">
              <Statistic
                title="Last 7 Days Clicks"
                value={clicksGraph}
                loading={chartLoadingTracker}
              />
            </Col>
            <Col span={12} className="d-flex flex-column align-items-center">
              <Statistic
                title="Lifetime Clicks"
                value={lifeTimeClicks}
                loading={lifeTimeClicksLoader}
              />
            </Col>
          </Row>
        </div>
        <DateRangePickerFilter
          onChange={onDatePickerChange}
          datePickerError={datePickerError}
          dateString={dateString}
        />
        <div className="barChart" style={{ width: "100%", overflowX: "auto", overflowY: "scroll" }}>

          {exportData?.length > 0 && <BarChart

            graphCount={exportData}
            startDate={startDate}
            endDate={endDate}
            chartLoadingTracker={chartLoadingTracker}
            chartDownloadData={chartDownloadData}
            setChartDownloadData={setChartDownloadData}
          />}
        </div>

        {exportData?.length > 0 && <div className="d-flex justify-content-end w-100 mt-4 mb-5">

          <Button
            className="downloadChart"
            onClick={downloadChart}
          >
            Download Report
          </Button>
        </div>}

        {clickDetails?.[0]?.documents?.length>0 && <>
          
          <ClicksTable
            showDetails={showDetails}
            selectedCampaign={selectedCampaign}
            loading={tableLoadingTracker}
            tableDetails={clickDetails?.[0]?.documents}
            clicksTableFetch={clicksTableFetch}
            tableTotalCount={tableTotalCount}
          />
        </>
        }
      </div>
    </ClicksReportContainer>
  );
}

export default ClicksReport;
