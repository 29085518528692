/**
 * set user data in local storage
 */
export const setUserLocalStorage = (userData) => {
  if (userData) {
    localStorage.setItem('user', JSON.stringify(userData));
  }
};

/**
 * set user data from local storage
 */
export const getUserLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem('token'));
  const isValidatedToken = validateToken(user);

  if (isValidatedToken) {
    return user || '';

  } else {
    return '';
  }
};

/**
 * validate token
 */
export const validateToken = (user) => {
  if (user) {
    const splittedToken = user.split('.');
    
    if (splittedToken?.length > 1) {
      // const decodedToken = JSON.parse(atob(splittedToken[1]));
      // if (Date.now() > (decodedToken?.exp || 0) * 1000) {
      //   removeUser();
      // } else {
      return true;
      // }
    }
  }
  return false;
};

/**
 * remove user
 */
export const removeUser = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('userId');
  localStorage.removeItem('username');
};


