import React from "react";
import { Column} from "@ant-design/charts";
import moment from "moment";
import { formatDate } from "../../../utils/helper";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from 'antd';



const BarChart = ({
  graphCount,
  selectedCampaignClicksData,
  startDate,
  endDate,
  clicksCalculation,
  chartLoadingTracker,
  chartDownloadData,
  setChartDownloadData
}) => {

  
const chartData=graphCount&& graphCount?.map((value)=>{
  
return{
  Date:moment(value?.date).format("MMM D"),
  Clicks:value?.count
}
})
  

  const config = {
    data: chartData,
    xField: "Date",
    yField: "Clicks",
    color: "black",
    onReady: (chart) => {
      setChartDownloadData(chart);
      //     // setTimeout(() => {
      //         // '#ff000' also is not working
      //         // chart.downloadImage('ImageName', 'image/png', 'red');
      //     // }, 5000);
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    download: true,

  };
  return <>
  
 { graphCount && chartLoadingTracker ? <div style={{display:"flex", alignItems:"center", justifyContent:"center", padding:"40px 10px"}}><Spin size="large" /> </div> : 
   <div >
      <div style={{ width: "1000px" , padding:"40px 10px"}}>
   <Column {...config} />
   </div>
 </div>
 }
  </>

};

export default BarChart;
