import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./_layout.styled.js";
import { httpGet } from "../../utils/https";
import { LayoutContainer } from "./_layout.styled.js";
import { UpOutlined, DownOutlined, InfoCircleFilled } from "@ant-design/icons";
import {
  Menu,
  Space,
  Dropdown,
  Button,
  DatePicker,
  Select,
  Modal,
  Row,
  Col,
} from "antd";
import ViewsReport from "../views_report/viewsReport.js";
import Preview from "../preview/preview";
import LiveView from "../live_view/liveView.js";
import moment from "moment";
import logo from "../../assets/images/ic_binary_logo.svg";
import { URLS } from "../../utils/urls.js";
import ClicksReport from "../clicks_report/clicksReport.js";
import LogoutIcon from "../../assets/images/Logout Icon.svg";
import './layout.css'

const { Option } = Select;

function Layout(props) {
  const [isFetching, setIsFetching] = useState(false);
  const [clicksDataFetching, setClicksDataFetching] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [viewsReport, setViewsReport] = useState(false);
  const [preview, setPreview] = useState(true);
  const [liveView, setLiveView] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [clicksReport, setClicksReport] = useState(false);
  const [dateString, setDateString] = useState();
  const [selectedCampaignData, setSelectedCampaignData] = useState([]);
  const [formsData, setFormsData] = useState([]);
  const [dateErr, setDateErr] = useState();
  const userId = localStorage.getItem("userId");
  const accessibleCampaigns = [];
  const [viewsCount, setViewsCount] = useState();
  const [scansCount, setScansCount] = useState();
  const [campaignSummeryData, setCampaignSummeryData] = useState({});
  const [campaignClicksReportData, setCampaignClicksReportData] = useState({});
  const [campaignId, setCampaignId] = useState("");
  const [campaignData, setCampaignData] = useState({});
  const [dateChangeTracker, setDateChangeTracker] = useState(0);
  const [clicksReportsAvailable, setClicksReportAvailable] = useState(true);
  const [resetMapStyle, setResetMapStyle] = useState(
    "mapbox://styles/binaryadmin/ckzco0u9b004314rwv3tzkcn8"
  );
  const [open, setOpen] = useState(false);
  const [isCampaignIdAvailable, setIsCampaignIdAvailable] = useState(false);
  const [isCampaignDetailsOpen, setIsCampaignDetailsOpen] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [symbolicName, setSymbolicName] = useState("");

  const containerDivRef = useRef(null);

  useEffect(() => {
    fetchCampaignsList();
  
    const firstName = JSON.parse(localStorage.getItem("first_name"));
    const lastName = JSON.parse(localStorage.getItem("last_name"));

    if (firstName != 'null' && lastName !== 'null'){
      
      setDisplayName(firstName + " " + lastName);
      setSymbolicName(firstName[0] + lastName[0]);
    }
    
    if (firstName ==='null') {
      
      setDisplayName(lastName);
      setSymbolicName(lastName[0]);
    }
    if(lastName ==='null'){

      setDisplayName(firstName);
      setSymbolicName(firstName[0]);
    }
     
  }, []);



  useEffect(() => {
    if (campaignId) {
      setIsCampaignIdAvailable(true);
    }

    if (selectedCampaignData?.[0]?.is_redirect_url_available) {
      setClicksReportAvailable(true);
    } else {
      previewCallback();
      setClicksReportAvailable(false);
    }
  }, [campaignId]);


 
  const fetchMixPanelEventsData = () => {
    setClicksDataFetching(true);
  };
  const previewCallback = () => {
    setPreview(true);
    setViewsReport(false);
    setLiveView(false);
    setClicksReport(false);
  };
  const viewsReportCallback = () => {
    setViewsReport(true);
    setLiveView(false);
    setPreview(false);
    setClicksReport(false);
  };
  const liveViewCallback = () => {
    setViewsReport(false);
    setLiveView(true);
    setPreview(false);
    setClicksReport(false);
  };
 
  
  const clicksReportCallback = () => {
    setViewsReport(false);
    setLiveView(false);
    setPreview(false);
    setClicksReport(true);
  };

  const fetchCampaignsList = () => {
    setIsFetching(true);

    const userId = JSON.parse(localStorage.getItem("user_id"));

    const data = { user_id: userId, device_type: "Reporting" };

    const url = URLS?.CAMPAIGNS?.GET_USER_CAMPAIGN_LIST;
    httpGet(url, { headers: data })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data.length > 0) {
            const campaignListData = res?.data.map((data) => {
              return {
                ...data,
                key: data?._id,
                campaign_name: data?.campaign_name || "",
              };
            });

            const sortedCampaigns = campaignListData.sort((a, b) => {
              if (
                a.campaign_status === "RUNNING" &&
                b.campaign_status !== "RUNNING"
              ) {
                return -1;
              }
              if (
                a.campaign_status !== "RUNNING" &&
                b.campaign_status === "RUNNING"
              ) {
                return 1;
              }
              return 0;
            });
            setCampaignsList(sortedCampaigns || []);

            const filterWithMediaCampaigns = campaignListData.filter(
              (item) => item?.media_video_url
            );
            if (
              filterWithMediaCampaigns &&
              filterWithMediaCampaigns.length >= 1 &&
              filterWithMediaCampaigns[0] &&
              filterWithMediaCampaigns[0]?._id
            ) {
              setSelectedCampaign(filterWithMediaCampaigns[0]?._id);
              setSelectedCampaignData([filterWithMediaCampaigns[0]]);
            }
          }
        }
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const handleLogout = () => {
    setOpen(true);
  };
  const datePicker = (date, dateString) => {
    var startDate = moment(dateString[0]);
    var endDate = moment(dateString[1]);
    if (endDate.diff(startDate, "days") > 30) {
      setDateErr("Dates More Than One Month is Not Allowed");
      setDateString("");
    } else {
      setDateErr("");
      setDateString(dateString);
      setDateChangeTracker(dateChangeTracker + 1);
    }
  };
  const handleChange = (campaignId) => {
    const data = campaignsList.filter((e) => e._id == campaignId);
    setSelectedCampaign(campaignId);
    setCampaignId(campaignId);
    setSelectedCampaignData(data);
  };
  

  if (campaignsList) {
    campaignsList.filter((campaign) => {
      if (campaign.accessible_to) {
        campaign.accessible_to.map((id) => {
          if (id == userId) {
            accessibleCampaigns.push(campaign);
          }
        });
      }
    });
  }

  const onScrollToTop = () => {
    if (containerDivRef.current) {
      containerDivRef.current.scrollTop = 0;
    }
  };

  const filteration = (input, option) => {
    return option?.name?.toLowerCase().includes(input?.toLowerCase());
  };

  const handleOk = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");

    window.location.reload();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <LayoutContainer ref={containerDivRef}>
        <nav className="navbar navbar-light top-navbar bg-light">
          <div className="logo navbar-brand">
            <img src={logo} alt="logo" height="30px" />
          </div>
          <div className="container-fluid d-flex align-items-center">
            <div className="dropdown me-2 ">
              <div
                style={{
                  width: "2.8em",
                  height: "2.8em",
                  borderRadius: "100px",
                  background: "black",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    fontSize: "1em",
                    lineHeight: "2.8em",
                  }}
                >
                  {symbolicName}
                </div>
              </div>

            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#top-navigation"
              aria-controls="top-navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="top-navigation"
              data-bs-toggle="collapse"
              data-bs-target="#top-navigation.show"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Button
                    className="nav-btn w-100"
                    onClick={previewCallback}
                    style={{ color: preview && "black" }}
                  >
                    Preview
                  </Button>
                </li>
                {/* {isCampaignIdAvailable && (
                  <li className="nav-item">
                    <Button
                      className="nav-btn w-100"
                      onClick={liveViewCallback}
                      style={{ color: liveView && "black" }}
                    >
                      Live View
                    </Button>
                  </li>
                )} */}

                {isCampaignIdAvailable && (
                  <li className="nav-item">
                    <Button
                      className="nav-btn w-100"
                      onClick={viewsReportCallback}
                      style={{ color: viewsReport && "black" }}
                    >
                      Spots Report
                    </Button>
                  </li>
                )}
                
               
                {clicksReportsAvailable && isCampaignIdAvailable && (
                  <li className="nav-item">
                    <Button
                      className="nav-btn w-100"
                      onClick={clicksReportCallback}
                      style={{ color: clicksReport && "black" }}
                    >
                      Clicks Report
                    </Button>
                  </li>
                )}
                
                <li>
                  <Button
                    className="nav-btn w-100"
                    onClick={handleLogout}
                    style={{
                      color: "red",
                      fontWeight: 400,
                      fontSize: "18px",
                    }}
                  >
                    <img src={LogoutIcon} className="me-2"></img>
                    Logout
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="mainContainer">
          <div className="leftNav">
            <div className="logo">
              <img src={logo} alt="logo" height="35px" />
            </div>
            <div className="navigation">
              <li>
                <Button
                  className="nav-btn"
                  onClick={previewCallback}
                  style={{ color: preview && "black" }}
                >
                  Preview
                </Button>
              </li>
              {/* {isCampaignIdAvailable && (
                <li>
                  <Button
                    className="nav-btn"
                    onClick={liveViewCallback}
                    style={{ color: liveView && "black" }}
                  >
                    Live View
                  </Button>
                </li>
              )} */}
              {isCampaignIdAvailable && (
                <li>
                  <Button
                    className="nav-btn"
                    onClick={viewsReportCallback}
                    style={{ color: viewsReport && "black" }}
                  >
                    Spots Report
                  </Button>
                </li>
              )}
              
              
              {clicksReportsAvailable && isCampaignIdAvailable && (
                <li>
                  <Button
                    className="nav-btn"
                    onClick={clicksReportCallback}
                    style={{ color: clicksReport && "black" }}
                  >
                    Clicks Report
                  </Button>
                </li>
              )}{" "}
              
              <li>
                <Button
                  className="nav-btn"
                  onClick={handleLogout}
                  style={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "18px",
                    marginTop: "10px",
                  }}
                >
                  <img src={LogoutIcon} className="me-2"></img>
                  Logout
                </Button>
              </li>
            </div>
            
          </div>
          <Modal
            title={
              <span style={{ fontSize: "22px", fontWeight: 700 }}>Logout</span>
            }
            visible={open}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Okay"
            okButtonProps={{
              style: {
                borderRadius: "20px",
                backgroundColor: "black",
                borderColor: "black",
                padding: "5px 30px 30px 30px",
              },
            }}
            cancelButtonProps={{
              style: {
                borderRadius: "20px",
                backgroundColor: "white",
                padding: "5px 30px 30px 30px",
              },
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 500 }}>
              Are you sure you want to Logout?
            </div>
          </Modal>

          <div className="middle">
            <h1 className="heading">
              Campaign
              {isCampaignIdAvailable && (
                <InfoCircleFilled
                  className="info-icon"
                  onClick={() => setIsCampaignDetailsOpen(true)}
                />
              )}
            </h1>
            <div className="dropdown d-flex align-items-center">
              <Select
                placeholder={isFetching ? "Fetching..." : "Select Campaign"}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => filteration(input, option)}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={handleChange}
                dropdownStyle={{
                  borderRadius: "25px",
                  padding: "1.5rem",
                  fontFamily: "Roboto",
                  letterSpacing: "1.8px",
                }}
              >
                {campaignsList &&
                  campaignsList.length > 0 &&
                  campaignsList?.map((item) => {
                    return (
                      <Option
                        value={item?._id}
                        key={item?._id}
                        name={item?.campaign_name}
                        style={{ fontSize: "16px", color: "#707070" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              height: "8px",
                              width: "8px",
                              marginRight: "8px",
                              background:
                                item?.campaign_status == "RUNNING"
                                  ? "green"
                                  : "yellow",
                              borderRadius: "10px",
                            }}
                        ></div>
                          <div className="truncated-word">{item?.campaign_name || ""}</div>
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <Modal
              title={
                <span style={{ fontSize: "22px", fontWeight: 700 }}>
                  Campaign Details
                </span>
              }
              onCancel={() => setIsCampaignDetailsOpen(false)}
              visible={isCampaignDetailsOpen}
              footer={null}
            >
              <div className="details-block d-flex justify-content-center">
                <Row gutter={[16, 16]} justify="center">
                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Company
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.campaign_company?.company_name
                        ? selectedCampaignData?.[0]?.campaign_company
                            ?.company_name
                        : "-"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Industry
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.campaign_industry
                        ?.industry_name
                        ? selectedCampaignData?.[0]?.campaign_industry
                            ?.industry_name
                        : "-"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Type
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.campaign_type
                        ?.campaign_type_name
                        ? selectedCampaignData?.[0]?.campaign_type
                            ?.campaign_type_name
                        : "-"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Frequency
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.per_ride_frequency
                        ? selectedCampaignData?.[0]?.per_ride_frequency
                        : "-"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Campaign Start
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.start_date
                        ? moment(
                            selectedCampaignData?.[0]?.start_date * 1000
                          ).format("MMMM D, YYYY")
                        : "-"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Campaign End
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.end_date
                        ? moment(
                            selectedCampaignData?.[0]?.end_date * 1000
                          ).format("MMMM D, YYYY")
                        : "-"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Status
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.campaign_status
                        ? selectedCampaignData?.[0]?.campaign_status
                        : "-"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Active/Inactive
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.is_active
                        ? "Active"
                        : "Inactive"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Alloted Slots
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.allotted_slots
                        ? selectedCampaignData?.[0]?.allotted_slots
                        : "-"}
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <div style={{ fontSize: "17px", fontWeight: 500 }}>
                      Served Slots
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 300 }}>
                      {selectedCampaignData?.[0]?.served_slots
                        ? selectedCampaignData?.[0]?.served_slots
                        : "-"}
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal>

            
            {viewsReport && (
              <ViewsReport
                viewsCount={viewsCount}
                dateString={dateString}
                selectedCampaign={selectedCampaign}
                selectedCampaignData={selectedCampaignData}
                onDatePickerChange={datePicker}
                datePickerError={dateErr}
                campaignId={campaignId}
                dateChangeTracker={dateChangeTracker}
                resetMapStyle={resetMapStyle}
              />
            )}
            {preview && (
              <Preview
                selectedCampaignData={selectedCampaignData}
                campaignId={campaignId}
                setCampaignId={setCampaignId}
                setCampaignData={setCampaignData}
                campaignData={campaignData}
              />
            )}
            {liveView && (
              <LiveView
                scansCount={scansCount}
                viewsCount={viewsCount}
                selectedCampaign={selectedCampaign}
                selectedCampaignData={selectedCampaignData}
                formsData={formsData}
                campaignSummeryData={campaignSummeryData}
                campaignId={campaignId}
              />
            )}
            
            
            
            {clicksReport && (
              <ClicksReport
                dateString={dateString}
                selectedCampaign={selectedCampaign}
                selectedCampaignData={selectedCampaignData}
                onDatePickerChange={datePicker}
                datePickerError={dateErr}
                clicksDataFetching={clicksDataFetching}
                campaignClicksReportData={campaignClicksReportData}
                campaignId={campaignId}
                dateChangeTracker={dateChangeTracker}
              />
            )}
          </div>
          <div className="rightNav ps-3">
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "60px" }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "100px",
                  background: "black",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    fontSize: "16px",
                    lineHeight: "50px",
                  }}
                >
                  {symbolicName}
                </div>
              </div>
            </div>
            <div className="text-center mt-1">
              <div style={{ fontWeight: "bold" }}>{displayName}</div>
              {JSON.parse(localStorage.getItem("email"))}
            </div>


          </div>
        </div>
        <footer className="justify-content-between w-100">
          <div className="backTop">
            <div
              onClick={onScrollToTop}
              className="d-flex align-items-center backTop-subDiv"
              role="button"
            >
              <UpOutlined />
              <span className="backTop-label">{"Back to Top"}</span>
            </div>
          </div>
          <div className="text-center footer-site-link">
            <a
              href="https://www.binarymedia.io/"
              target="_blank"
              className="footer-site-label"
            >
              {"binarymedia.io"}
            </a>
          </div>
          <div className="socialLinks">
            <li>
              <a
                href="https://www.linkedin.com/company/enterbinary"
                target="_blank"
              >
                Ld
              </a>
            </li>
            <li>
              <a href="https://twitter.com/enterbinary" target="_blank">
                Tw
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/enterbinary" target="_blank">
                Fb
              </a>
            </li>
          </div>
        </footer>
      </LayoutContainer>
    </>
  );
}
export default Layout;
