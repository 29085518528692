import styled from "styled-components";
export const PreviewContainer = styled.div`
  font-family: "Poppins", sans-serif;

  .container {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin-bottom: 10vh;
  }

  .playBtn {

    font-weight: 800;
    background: black;
    color: white;
    width: 216px;
    height: 48px;
    font-size: 15px;
    border-radius: 6vh;
    margin-top: 60px;
margin-bottom:10px;
    @media only screen and (max-width: 768px) {
      height: 32px;
      width: 130px;
      font-size: 14px;
    }
    @media only screen and (max-width: 576px) {
      font-size: 13px;
    }
  }

  .playBtn[disabled],
  .playBtn[disabled]:hover,
  .playBtn[disabled]:focus,
  .playBtn[disabled]:active {
    color: #fff;
    background: black;
    text-shadow: none;
    box-shadow: none;
  }

  .player {
    position: relative;
  }
  .video-box {
    background: black;
    position: absolute;
    top: 9.3%;
    width: calc(100% - 9.4%);
    height: calc(100% - 18.7%);
    left: 4.8%;
  }
  .image-box {
    background: transparent;
    position: absolute;
    top: 9%;
    width: calc(100% - 8%);
    height: calc(100% - 18%);
    left: 4.2%;
  }
  .mediaNotFound {
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 5%;
    font-size: xxx-large;
    font-weight: 600;
  }
  .preview-image {
    width: 100% !important;
    height: inherit !important;
  }
  .preview-full-image {
    width: 100% !important;
    height: 100% !important;
  }
`;
