import styled from "styled-components";
export const LayoutContainer = styled.div`
  font-family: "Roboto", sans-serif;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;


.info-icon{
font-size:0.8em; 
margin-left:0.5em;
color:black
}

  .grid-layout{
  display:flex;
  background-red;
  width:100%;
  }

  .header-left{
  margin-right:30px;
  }

  .campaign-details-btn {
    @media only screen and (min-width: 990px) {
      display: none;
    }
  }
  .details-block {
    margin-top: 40px;
    display:flex;
    justify-content:center;
  }
  .details-header {
    font-size: 12px;
    font-weight: 700;
    
  }
  .details-data {
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 20px;

  }
  .mainContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .heading {
    font: normal normal 800 28px/34px Poppins;
    letter-spacing: 0px;
    color: #000;
    @media only screen and (min-width: 2060px) {
      margin-top: 4%;
      font-size: 50px;
    }
  }

  .ant-select-selector {
    width: 45vw !important;
    max-width: 700px;
    border: 1px solid black !important;
    border-radius: 35px !important;
    font-size: 25px;
    // height: 70px !important;
    align-items: center !important;
    text-align: center !important;
    color: #000 !important;
    min-width: 240px;
    padding: 2rem !important;
    @media only screen and (max-width: 768px) {
      padding: 1.4rem !important;
      font-size: 1rem;
    }
  }
  .ant-select-selector span.ant-select-selection-search {
    display: flex;
    align-items: center;
  }
  span.ant-select-selection-search .ant-select-selection-search-input {
    margin-left: 2.25rem !important;
    text-align: left;
    color: #707070 !important;
    font: normal normal 300 18px/150px Roboto;
    -webkit-letter-spacing: 1.8px;
    -moz-letter-spacing: 1.8px;
    -ms-letter-spacing: 1.8px;
    letter-spacing: 1.8px;

    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-left: 1.15rem !important;
    }
  }
  .ant-select-selector span.ant-select-selection-item {
  display:flex;
align-items:center;
  text-align: left;
    color: #707070 !important;
    font: normal normal 300 18px Roboto;
    letter-spacing: 1.8px;
    margin-left: 1rem;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-left: 0.5rem;
       display: block !important;
            margin-top: 10px;
    }
  }
  .ant-select span.ant-select-arrow {
    top: 45%;
    right: 36px;
    @media only screen and (max-width: 768px) {
      right: 20px;
    }
  }
  .ant-select span.ant-select-arrow svg {
    height: 1.5rem;
    width: 1.5rem;
    color: #000;
    @media only screen and (max-width: 768px) {
      height: 1rem;
      width: 1rem;
    }
  }
  .leftNav {
    font-family: "Poppins", sans-serif;
    order: 1;
    font-weight: 800;
    left: 0;
    height: 100%;
    min-width: 240px;
    max-width: 340px;
    width: 15%;
    text-align: left;
    display: flex;
    justify-content: left;
    margin-top: 60px;
    padding-left: 22px;
    @media only screen and (min-width: 2060px) {
      max-width: unset;
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .middle {
    order: 2;
    // min-width: 70% !important;
    width: 70% !important;
    min-height: 100%;
    padding: 100px 5%;
    border-right: 1px solid #e8e8e9;
    border-left: 1px solid #e8e8e9;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 992px) {
      order: 1;
      min-width: 100% !important;
      width: 100% !important;
      border-right: none;
      border-left: none;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .rightNav {
    order: 3;
    min-width: 200px;
    max-width: 340px;
    width: 15%;
    right: 0;
    height: 100%;
    @media only screen and (min-width: 2060px) {
      max-width: unset;
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  footer {
    font-family: "Poppins", sans-serif;
    left: 0;
    bottom: 0;
    display: inline-flex;
    width: 100%;
    background: #131415 0% 0% no-repeat padding-box;
    // gap: 34%;
    min-width: 100%;
    align-items: center;
    justify-content: center;
    height: 6.5vh;
    color: #bbbbbb;
    position: fixed;
    font: normal normal 300 14px Roboto;
    z-index: 2;
    @media only screen and (min-width: 2000px) {
      font-size: 25px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 13px;
    }
  }
  footer a {
    text-decoration: none;
    color: white;
  }
  .navigation,
  li {
    list-style: none;
  }
  .top-navbar {
    display: none;
    @media only screen and (max-width: 992px) {
      display: block;
    }
  }
  .top-navbar .container-fluid {
    align-items: flex-start;
    justify-content: flex-end;
  }
  .top-navigation {
    margin-top: 1rem;
  }
  .navigation Button,
  .top-navbar .nav-btn {
    border: none;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 800;
    color: #9d9e9f;
    background: transparent;
    @media only screen and (min-width: 2060px) {
      font-size: 35px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .top-navbar .nav-btn {
    height: unset;
  }
  .navigation {
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    position: fixed;
    font-size: large;
    margin-top: 5%;
  }
  #components-dropdown-demo-arrow .ant-btn {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 8px;
  }
  .logout-btn {
  }
  .backTop {
    margin-left: 22px;
    width: 15%;
    min-width: max-content;
    @media only screen and (max-width: 992px) {
      padding-left: 0;
    }
  }
  .footer-site-link {
    width: 70%;
  }
  .footer-site-label {
    letter-spacing: 1.4px;
    color: #ffffff;
  }
  .backTop span.backTop-label {
    letter-spacing: 1.4px;
    color: #ffffff;
    margin-left: 12px;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
  .socialLinks {
    width: 15%;
    display: inline-flex;
    list-style: none;
    gap: 21px;
    color: #ffffff;
    letter-spacing: 1.4px;
    margin-right: 22px;
    justify-content: end;
    @media only screen and (max-width: 425px) {
      gap: 16px;
    }
    @media only screen and (max-width: 992px) {
      margin-right: 0;
    }
  }
  .ant-dropdown-trigger {
    border-radius: 16px;
    border: 0.5px solid #707070;
    @media only screen and (min-width: 2060px) {
      border-radius: 32px;
    }
  }
  .rightNav .dropdown {
    // position: fixed;
    margin-top: 60px;
  }
  .ant-dropdown-open {
    @media only screen and (min-width: 2060px) {
      height: 35px;
      width: 217px;
    }
  }

  .logo {
    position: fixed;
  }

  .ant-btn.nav-btn {
    padding: 10px 0;
  }
  .user-dropdown {
    height: 35px;
    font-size: 13px;
    font-weight: 500;
    padding: 1rem;
    color: #000;
    display: flex;
    align-items: center;
    @media only screen and (min-width: 2060px) {
      font-size: 22px;
      height: 55px;
    }
  }
  .user-dropdown .user-dropdown-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media only screen and (max-width: 576px) {
      width: 100px;
    }
    @media only screen and (max-width: 1200px) and (max-width: 1440px) {
      width: 100px;
    }
    @media only screen and (max-width: 992px) and (max-width: 1999px) {
      width: 80px;
    }
  }
  .user-dropdown .anticon-down {
    margin-left: 12px;
  }
  .navbar,
  .mainContainer,
  footer {
    padding: 1rem;
  }
  .navbar {
    position: fixed;
    width: 100%;
    background: #000;
    z-index: 99;
  }
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-toggler {
    @media only screen and (max-width: 576px) {
      font-size: 1rem;
    }
  }
  .hideDetailsbtn:hover,
  .hideDetailsbtn:focus,
  .downloadbtn:hover,
  .downloadbtn:focus,
  .playBtn:hover,
  .playBtn:focus {
    border-color: transparent !important;
  }
  .backTop-subDiv {
    width: max-content;
    cursor: pointer;
  }
  .datePicker {
    top: 180px;
  }
`;
