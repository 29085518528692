import firebase from 'firebase';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

const app = {
  // apiKey: "AIzaSyBOQMK5UGebR0w4WGTm5d1tpXX6nF5qaTA",
  apiKey: "AIzaSyBOQMK5UGebR0w4WGTm5d1tpXX6nF5qaTA",
  authDomain: "binaryos-312113.firebaseapp.com",
  projectId: "binaryos-312113",
  storageBucket: "binaryos-312113.appspot.com",
  messagingSenderId: "301325516258",
  appId: "1:301325516258:web:d7856a343f748b6fc90dbb",
  measurementId:"G-Y38DMYHDKN",
  databaseURL: "https://binaryos-312113-default-rtdb.firebaseio.com",
};

const fire = firebase.initializeApp(app);
firebase.analytics();

export const auth = fire.auth();
export const fireStoreDb = fire.firestore();
export const firebaseDb = fire.database('https://binaryos-prod-v2.firebaseio.com');
export const mainFirebase = firebase.firestore;
export const firebaseStorage = firebase.storage();
export default app;
