import React, { useEffect, useState } from "react";
import { PreviewContainer } from "./_preview.styled";
import ReactPlayer from "react-player";
import { Button } from "antd";
import TabletBorder from "../../assets/images/tablet-border.png";

function Preview({ selectedCampaignData, campaignId, setCampaignId, campaignData, setCampaignData }) {
  const [videoUrl, setVideoUrl] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [campaignVideoUrl, setCampaignVideoUrl]=useState("");
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const [mediaPlaying, setMediaPlaying] = useState(false);
  const [tabletPreviewImg, setTabletPreviewImg] = useState(TabletBorder);
  const [campaignImageUrl,setCampaignImageUrl]=useState("")

  

  const selectedCampaignType =
    (selectedCampaignData && selectedCampaignData[0]?.campaign_type) || "";


  const setInitialState = () => {
    setCampaignVideoUrl("")
    setCampaignImageUrl("")
    setVideoUrl("");
    setImageUrl("");
    setVideoPlaying(false);
    setIsVideoFinished(false);
    setMediaPlaying(false);
    setTabletPreviewImg(TabletBorder);
  };

  useEffect(() => {
    setInitialState();
  }, [campaignData]);

  useEffect(() => {
    if (
      isVideoFinished 
      // &&
      // selectedCampaignType === CAMPAIGN_TYPES.CAPTURE_LEADS
    ) {
      // setTabletPreviewImg(SMSTabletBorder);
    }
  }, [isVideoFinished]);


  const onVideoEnd = () => {
    if (campaignImageUrl) {
      setVideoPlaying(false);
      setMediaPlaying(false);
      setIsVideoFinished(true);
      setTimeout(() => {
        setMediaPlaying(false)
        setTabletPreviewImg(TabletBorder);
      }, 8000);
    } else {
      

      setVideoPlaying(false);
      setMediaPlaying(false);
      setTimeout(() => {
        setTabletPreviewImg(TabletBorder);
      }, 8000);
    }
  };

  const mediaNotFound = () => {
    return <h1 className="mediaNotFound">{"Media Not Found!"}</h1>;
  };

  const onMediaPlayPauseBtnAction = () => {
    setMediaPlaying(true);
    if ( selectedCampaignData?.[0]?.campaign_video?.campaign_video_url) {
      setVideoPlaying(!videoPlaying);
    setCampaignVideoUrl(selectedCampaignData?.[0]?.campaign_video?.campaign_video_url)
    }
    if (selectedCampaignData?.[0]?.campaign_image?.campaign_image_url) {
      setCampaignImageUrl(selectedCampaignData?.[0]?.campaign_image?.campaign_image_url)
    }
    !videoPlaying && setIsVideoFinished(false);
  };


  return (
    <PreviewContainer>
      <div className="container">
        <Button
          className="playBtn"
          disabled={!selectedCampaignData}
          // type="primary"
          htmlType="submit"
          onClick={() => onMediaPlayPauseBtnAction()}
        >
          {videoPlaying ? <>PAUSE</> : <>PLAY</>}
        </Button>
        <div className="player mt-3">
          <img
            src={tabletPreviewImg}
            alt="tablet"
            style={{
              width: "100%",
            }}
          />
          <div
            className={`${
              isVideoFinished &&
              !videoPlaying &&
              campaignImageUrl
                ? "image-box"
                : "video-box"
            }`}
          >
            {!isVideoFinished && campaignVideoUrl && (
             
             <ReactPlayer
                url={campaignVideoUrl}
                width="100%"
                height="100%"
                playing={videoPlaying}
                onEnded={onVideoEnd}
              />
            
            )}
            {(isVideoFinished && !videoPlaying && campaignImageUrl) ||
            (!campaignVideoUrl && campaignImageUrl) ? (
              <img
                src={campaignImageUrl}
                alt="img"
                className={`${
                  campaignImageUrl &&
                    // ? "preview-image"
                    // :
                     "preview-full-image"
                }`}
              />
            ) : null}
            {mediaPlaying && !campaignVideoUrl && !campaignImageUrl && mediaNotFound()}
          </div>
        </div>
      </div>
    </PreviewContainer>
  );
}

export default Preview;
