import styled from "styled-components";
export const ClicksReportContainer = styled.div`
  width: 100%;
  .table > :not(caption) > * > * {
    padding: 0rem 0.5rem;
  }
  .container {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }

  .ant-statistic-content-value {
    font: 300 60px /60px Roboto !important;
    @media only screen and (max-width: 767px) {
      font: 300 37px / 37px Roboto !important;
    }
  }

  .downloadChart {
    color: white;
    border-radius: 35px;
    letter-spacing: 1.5px;
    font: normal normal 800 15px/21px Poppins;
    padding: 13px 30px 33px 30px;
    background: #000000 0% 0% no-repeat padding-box;

    @media only screen and (max-width: 768px) {
      padding: 8px 30px 28px 30px;
      font-size: 14px;
    }
    @media only screen and (max-width: 576px) {
      font-size: 13px;
      padding: 8px 30px 28px 30px;
    }
  }
  .ant-pagination-options {
    display: none !important;
  }
  .barChart {
    margin-top: 10px;
    width: 100%;
  }
  .file-export-loading {
    display: flex;
    align-items: center;
    padding-left: 60%;

    div {
      font-weight: 500;
      color: #000000;
      margin-left: 10px;
    }
  }
  .numberData {
    margin-top: 48px;
    margin-bottom: 32px;
  }
  .dateRangeP {
    width: max-content;
    font: normal normal 300 16px Roboto;
    letter-spacing: 1.6px;
    color: #707070;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  .ant-statistic-content {
    text-align: center !important;
  }
  .ant-statistic {
    width: max-content;
  }
  .ant-statistic-title {
    margin-bottom: 4px;
    color: black;
    font: normal normal bold 20px/34px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
  }
  .ant-statistic-content-value {
    display: inline-block;
    direction: ltr;
    font: normal normal 300 60px/100px Roboto;
    letter-spacing: 0px;
    color: #636465;
    @media only screen and (max-width: 768px) {
      font-size: 30px;
    }
  }
  .hideDetailsbtn {
    margin: 65px 0px 36px 0px;
    width: 216px;
    height: 47px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 35px;
    font: normal normal 800 15px/21px Poppins;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-transform: uppercase !important;
    @media only screen and (max-width: 768px) {
      width: 165px;
      height: 35px;
      font-size: 14px;
    }
    @media only screen and (max-width: 576px) {
      width: 155px;
      font-size: 13px;
    }
  }
  .ant-table-tbody {
    text-align: center;
  }
  #canvas_1 {
    width: 640px !important;
  }

  .ant-table-pagination-right {
    justify-content: flex-start !important;
  }
  .ant-table-cell {
    text-align: center;
  }
  .downloadbtn {
    // margin-left: 68vh;
    width: 216px;
    height: 47px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 35px;
    font: normal normal 800 15px/21px Poppins;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-transform: uppercase !important;
    @media only screen and (max-width: 768px) {
      width: 165px;
      height: 35px;
      font-size: 14px;
      padding: 0;
    }
    @media only screen and (max-width: 576px) {
      width: 155px;
      font-size: 13px;
      padding: 0;
    }
  }
  .hideDetailsbtn:hover,
  .hideDetailsbtn:focus,
  .downloadbtn:hover,
  .downloadbtn:focus {
    border-color: transparent !important;
  }
`;
