import React, { useState, useEffect, Fragment } from "react";
import { Table } from "antd";
import { formatDate } from "../../../utils/helper";
import { Loader } from "../../layout/_common.styled";
import moment from "moment";
import { Button } from "antd"
import Downloadmodal from "../../download_modal/Downloadmodal";
import GoTo from "../../../component/go-to-page";
import { createFromIconfontCN } from '@ant-design/icons';



const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});


function ClicksTable({
  showDetails,
  selectedCampaignClicksData,
  loading,
  clicksCalculation,
  tableDetails=[],
  clicksTableFetch,
  tableTotalCount,
  selectedCampaign
}) {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    tableDataShow();
  }, [tableDetails]);



  const handlePaginationChange = (pagination, filters, sorter) => {
    const skip = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    
    
    setCurrentPage(pagination?.current)
    clicksTableFetch(pagination?.current, pagination?.pageSize)
  };

  const tableDataShow = () => {
    const processedTableData = tableDetails && tableDetails.map((value) => {
      const latitude = value?.location?.coordinates[0];
      const longitude = value?.location?.coordinates[1];
      const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  
      return {
        date: moment(value?.click_time * 1000).format("MMMM D, YYYY h:mm A"),
        Location: (
          <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" className="d-flex align-items-center">
            Click Location
            <IconFont type="icon-tuichu" className="ms-1" />
          </a>
        ),
        taxi_number: value?.taxi_number,
        device_serial: value?.device_serial
      };
    });
  
    setTableData(processedTableData);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Device Serial",
      dataIndex: "device_serial",
      key: "device_serial",
    },
    {
      title: "Taxi Number",
      dataIndex: "taxi_number",
      key: "taxi_number",
    },
    {
      title: "Click Location",
      dataIndex: "Location",
      key: "Location",
    },
  ];

  

  return (
    <Fragment>
      {showDetails && (
        <div className="table">
          <Table
            loading={loading}
            columns={columns}
            dataSource={tableData}
            onChange={handlePaginationChange}
            pagination={{ current: currentPage, defaultPageSize: 20, total: tableTotalCount }}
            scroll={{ x: 700, y: 900 }}
          />
          <GoTo
            currentPage={currentPage}
            pageSize={20} 
            total={tableTotalCount}
            onChange={(pageNumber) => handlePaginationChange({
              "current": pageNumber,
              "pageSize": 20,
              "defaultPageSize": 20,
              "total": tableTotalCount
            })} 
            setCurrentPage={setCurrentPage}
          />
          <div className="d-flex justify-content-end">
            <Button
              className="downloadbtn"
              onClick={() => setOpen(!open)}
            >
             Export</Button>
            <Downloadmodal open={open} setOpen={setOpen} tabDetails={"Clicks Report Export"} selectedCampaign={selectedCampaign}></Downloadmodal>
          </div>

        </div>
      )}
    </Fragment>
  );
}

export default ClicksTable;
