import styled from "styled-components";
export const DatePickerContainer = styled.div`
  font-family: "Roboto", sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .date-error {
    font: normal normal 400 14px Poppins;
    margin-top: 12px;
    text-align: center;
    color: red;
    @media only screen and (max-width: 992px) {
      font-size: 12px !important;
    }
    @media only screen and (min-width: 2060px) {
      font-size: 18px !important;
    }
  }

  .date-error-resp {
    font: normal normal 400 14px Poppins;
    margin-top: 10px;
    text-align: center;
    color: red;
  }

  .date-picker-resp {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: block !important;
    }
  }

  .ant-picker-panels {
    display: inline-grid !important;
  }

  .ant-picker-panel-container {
    display: inline-grid !important;
  }

  .ant-picker-range {
    color: #000;
    font: normal normal 300 14px/26px Roboto;
    display: inline-flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 35px;
    height: 45px;
    border-color: #000;
    width: 90%;
    max-width: 393px;

    @media only screen and (max-width: 992px) {
      font-size: 12px !important;
      height: 40px;
    }
    @media only screen and (min-width: 2060px) {
      height: 55px;
      max-width: 450px;
    }
  }

  .ant-picker-input > input {
    font: normal normal 300 14px Roboto;
    letter-spacing: 1.4px;
    color: #707070;
    text-align: center;
    @media only screen and (min-width: 2060px) {
      font-size: 18px !important;
    }
    @media only screen and (max-width: 992px) {
      font-size: 12px !important;
    }
  }
`;
