import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Column } from "@ant-design/charts";
import moment from "moment";
import { Button, Spin } from "antd";
import "./barChart.css";

const DemoColumn = ({
  lifeTimeViews,
  startDate,
  endDate,
  chartLoadingTracker,
  chartDownloadData,
  setChartDownloadData
}) => {
  const [chartDetails, setChartDetails] = useState([]);

  useEffect(() => {
    const spotsToMap = lifeTimeViews?.map((val) => {
      return {
        date: moment(val?.date).format("MMM D"),
        count: val?.count,
      };
    });
    setChartDetails(spotsToMap);
  }, [lifeTimeViews]);

  
  const config = {
    data: chartDetails,
    xField: "date",
    yField: "count",
    color: "black",
    onReady: (chart) => {
      setChartDownloadData(chart);
      //     // setTimeout(() => {
      //         // '#ff000' also is not working
      //         // chart.downloadImage('ImageName', 'image/png', 'red');
      //     // }, 5000);
    },
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
  };

  

  return chartLoadingTracker ? (
    <div style={{ display: "flex", justifyContent: "center",padding:"40px 10px" }}>
      <Spin size="large" />
    </div>
  ) : (
    <div>
      <div style={{ width: "1000px" , padding:"40px 10px"}}>
        <Column {...config} />
      </div>
    </div>
  );
};

export default DemoColumn;
