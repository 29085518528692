import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from "../views/login";
import Layout from "../views/layout/layout";
import RedirectContent from "../views/RedirectPage/RedirectContent";
import { getUserLocalStorage } from "../utils/localstorage";

const ProtectedRoute = ({ element }) => {
  const userData = getUserLocalStorage();
  return userData ? element : <Navigate to="/login" />;
};

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute element={<Layout />} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/binary-test/:campaignId" element={<RedirectContent />} />
    </Routes>
  );
}